import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { sleep } from './../../../../../resources/Functions';
import Alert, { emptyAlert } from "../../../../alert/Alert";
import { rtDatabase, fireAuth } from "../../../../../Fire";
import "./EditPhotoModal.css";

const EditPhotoModal = (props) => {
  const { 
    show, 
    onHide, 
    pic,
    deleteImg
  } = props;
  const [intPic, setIntPic] = useState(pic);
  const [intShow, setIntShow] = useState(show);
  const [submitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState(emptyAlert);
  const [loadingVis, setLoadingVis] = useState(false);

  useEffect(() => {
    setIntShow(show);
  }, [show]);

  const handleHide = () => {
    if (!submitting) {
      setIntShow(false);
      sleep(500).then(() => onHide());
    }
  };

  useEffect(() => {
    let picRef = rtDatabase.ref("userData/" + fireAuth.currentUser.uid + "/carouselPics/" + pic.id);
    picRef.on("value", (snapshot) => {
      setIntPic(snapshot.val());
    });
    return () => picRef.off();
  }, [])

  const handleDelete = () => {
    setAlert({
      show: true,
      actionNeeded: true,
      confirmText: "Delete",
      title: "Delete Carousel Image",
      message:
        "Are you sure you want to delete this image?",
      variant: "danger",
      confirmCallback: () => {
        deleteImg(intPic.id);
        setAlert(emptyAlert);
      },
      cancelCallback: () => setAlert(emptyAlert),
    })
  }

  const toggleVisibility = () => {
    console.log("toggling");
    setLoadingVis(true);
    let updates = {};
    updates["userData/" + fireAuth.currentUser.uid + "/carouselPics/" + pic.id + "/show"] = !intPic.show; 
    rtDatabase.ref().update(updates)
    .then(() => {})
    .catch(error => {
      console.error("Error toggling visibility");
      console.error(error);
    });
  }

  useEffect(() => {
    setLoadingVis(false);
  }, [intPic])

  return <>
    <Modal
      show={intShow}
      onHide={handleHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Edit Image
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="epm-btn-container">
          <div className="epm-btn-wrapper">
            <button 
              className={"epm-btn epm-vis-btn " + (!intPic.show ? "epm-vis-btn-hidden" : "")}
              onClick={toggleVisibility}
              disabled={loadingVis}
            >
              {!loadingVis ? 
                (intPic.show ? 
                  <i className="bi-eye epm-btn-icon" /> :
                  <i className="bi-eye-slash epm-btn-icon epm-btn-icon-white" />
                ) :
                (<Spinner animation="border"/>)
              }
            </button>
            <h4 className="epm-btn-title">
              {intPic.show ? "Hide" : "Show"}
            </h4>
          </div>
          <div className="epm-btn-wrapper">
            <button 
              className="epm-btn epm-del-btn"
              onClick={handleDelete}
            >
              <i className="bi-trash epm-btn-icon epm-btn-icon-white" />
            </button>
            <h4 className="epm-btn-title">Delete Image</h4>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
    {alert.show && (
      <Alert
        show={alert.show}
        title={alert.title}
        message={alert.message}
        actionNeeded={alert.actionNeeded}
        confirmText={alert.confirmText}
        variant={alert.variant}
        confirmCallback={alert.confirmCallback}
        cancelCallback={alert.cancelCallback}
      />
    )}
  </>
};

export default EditPhotoModal;
