import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { fireAuth, rtDatabase } from "../../../../../Fire";
import "./Banner.css";

const Banner = (props) => {
  const { 
    settings,
    className
  } = props;
  const [loaded, setLoaded] = useState(0);
  const [pics, setPics] = useState(false);

  useEffect(() => {
    const ref = rtDatabase.ref("userData/" + fireAuth.currentUser.uid + "/carouselPics");
    ref.on('value', snapshot => {
      setPics(snapshot.val());
      setLoaded(prev => prev + 1);
    })

    return () => ref.off();
  }, []);

  useEffect(() => {
    // for some reason loaded gets incremented to 3 when all is loaded and good to go
    // so we wait for that before we go reloading the page on every pic change
    if (loaded >= 3) {
      console.log("Reloading???");
      window.location.reload();
    }
    setLoaded(prev => prev + 1);
  }, [pics]);

  let items = Object.values(pics || {})
    .filter((pic) => {
      const criteria = pic.showGroups;
      const show = (settings || {}).showGroups === undefined ? true : settings.showGroups;
      if (show) {
        return pic.show && (criteria === undefined ? true : criteria);
      } else {
        return pic.show && !(criteria === undefined ? true : criteria);
      }
    })
    .map((pic, i) => (
      <Carousel.Item key={i}>
        <img
          className="d-block w-100 db-img"
          src={pic.link}
          alt={"Slide " + (i + 1).toString()}
        />
      </Carousel.Item>
    ));

  return (
    <>
      <Carousel className={className} interval={6000} controls={false}>
        {/* <Carousel.Item>
          <img
            className="d-block w-100 db-img"
            src="https://i.imgur.com/1bC8Nl8.jpg"
            alt="First slide"
          />
        </Carousel.Item> */}
        {items}
      </Carousel>
    </>
  );
};

export default Banner;
