import React, { useEffect, useState } from 'react';
import { rtDatabase, fireFuncs, fireAuth } from './../../../../Fire';
import { Button, Spinner, ProgressBar } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { sleep } from '../../../../resources/Functions';
import "./MobileLogin.css";

const MobileLogin = () => {
  const [code, setCode] = useState(null);
  const [delay, setDelay] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [redirect, setRedirect] = useState({
    done: false,
    path: "",
    state: {}
  })

  useEffect(() => {
    fireFuncs.httpsCallable("createLoginCode")({}).then((result) => {
      const tempCode = result.data.code;
      const tempDelay = result.data.delay;
      setCode(tempCode);
      setDelay(tempDelay);
      setCountdown(tempDelay);
    })
  }, [])

  useEffect(() => {
    if (code !== null) {
      // add listener to code location
      const ref = rtDatabase.ref("loginCodes/" + code);
      ref.on('value', snapshot => {
        if (!snapshot.exists()) {
          // code was deleted, refresh page to get a new one
          window.location.reload();
        } else if (snapshot.val().username.length > 0) {
          const username = snapshot.val().username;
          const password = snapshot.val().password;
          // log in with credentials at code location
          fireAuth.signInWithEmailAndPassword(username, password)
            .then(async () => {
              await sleep(500);
              let updates = {};
              updates['loginCodes/' + code] = null;
              await rtDatabase.ref().update(updates);
              console.log("Trying Redirecting to Dashboard")
              setRedirect({
                done: true,
                path: "/tv"
              })
            })
            .catch((error) => {
              console.error(error);
              // should probably do something else here.
              window.location.reload();
            })
        }
      })

      // remove listener
      return () => ref.off();
    }
  }, [code])

  useEffect(() => {
    if (delay) {
      let interval = setInterval(() => {
        setCountdown(prev => prev - 100);
      }, 100)

      return () => clearInterval(interval);
    }
  }, [delay])

  if (redirect.done) {
    return <Redirect to={{
      pathname: redirect.path,
      state: redirect.state || {}
    }} />
  }

  return <>
    <div className="mli-container">
      <h1 className="mli-title">TV Display Login</h1>
      <div className="mli-border" />
      <div className="mli-content">
        <ol className="mli-list">
          <li className="mli-item">
            Visit <span className="mli-url">mivodentv.com/setup</span> on your mobile device.
          </li>
          <li className="mli-item">
            Enter the following code:
          </li>
          <div className="mli-code-container">
            {code ? 
              <p className="mli-code">
                {code}
              </p> :
              <div>
                <Spinner variant="primary" animation="grow" size="lg" className="mli-spinner"/>
                <Spinner variant="primary" animation="grow" size="lg" className="mli-spinner"/>
                <Spinner variant="primary" animation="grow" size="lg" className="mli-spinner"/>
              </div>
            }
            {code && 
              <ProgressBar 
                now={100 - (countdown) / delay * 100} 
                animated
                style={{height: "2vh", width: "100%"}}
              />
            }
          </div>
        </ol>
        <div className="mli-btn-wrapper">
          <Button 
            className="mli-btn"
            onClick={() => window.location.reload()}
          >
            Refresh
          </Button>
        </div>
      </div>
    </div>
  </>
}

export default MobileLogin;