import React, { Component } from "react";
import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";
import Homepage from "./components/pages/homepage/Homepage";
import Dashboard from "./components/pages/dashboard/Dashboard";
import LogIn from "./components/pages/log-in/LogIn";
import CreateAccount from "./components/pages/create-account/CreateAccount";
import Setup from "./components/pages/setup/Setup";
import Error from "./components/pages/error/Error";
import "./App.css";
import { AuthProvider } from "./Auth";
import PrivateRoute from "./PrivateRoute";

class App extends Component {
  render() {
    const routes = (
      <div>
        {/* <ScrollToTop/> */}
        <Switch>
          <PrivateRoute path="/" component={Homepage} exact />
          <PrivateRoute path="/tv" component={Dashboard} exact />
          <Route path="/setup" component={Setup} exact />
          <Route path="/log-in" component={LogIn} exact />
          <Route path="/create-account" component={CreateAccount} exact />
          <Route render={(props) => <Error {...props} />} />
        </Switch>
      </div>
    );
    return (
      <AuthProvider value={this.state}>
        <BrowserRouter
          basename={process.env.PUBLIC_URL}
          onUpdate={() => window.scrollTo(0, 0)}
        >
          {routes}
        </BrowserRouter>
      </AuthProvider>
    );
  }
}

export default App;
