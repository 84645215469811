import React, { useState, useEffect } from 'react';
import { rtDatabase, fireAuth } from '../../../../../Fire';
import { Form } from 'react-bootstrap';
import "./Settings.css";
import { GroupData } from '../../../../../data/GroupData';
import ActiveGroups from './ActiveGroups';

const Settings = (props) => {
  const {
    showGroups,
    activeGroups,
  } = props;

  const handleChange = () => {
    let updates = {};
    updates['userData/' + fireAuth.currentUser.uid + '/settings/showGroups'] = showGroups === undefined ? false : !showGroups;
    rtDatabase.ref().update(updates);
  }

  return <>
    <div className="hgs-main">
      <h2 className="hp-title">Settings</h2>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label className="hgs-group-label">Show Color Groups</Form.Label>
          <Form.Check
            className="hgs-switch"
            type="switch"
            id="custom-switch"
            label={showGroups || showGroups === undefined ? "Shown" : "Hidden"}
            checked={(showGroups === undefined ? true : showGroups)}
            onChange={handleChange}
            disabled={showGroups === null}
          />
        </Form.Group>
      </Form>
      <ActiveGroups activeGroups={activeGroups} />
    </div>
  </>
}

export default Settings;