import React, { useState, useContext, useEffect } from "react";
import { fireAuth, rtDatabase } from "../../../Fire";
import { Redirect, Link } from "react-router-dom";
import { sleep } from "../../../resources/Functions";
import { Form, Button, Spinner } from 'react-bootstrap';
import { AuthContext } from './../../../Auth';

const CreateAccount = (props) => {
  const {
    code
  } = props.location.state || {};
  // state vars
  // used to display loading spinner in done button
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState({
    done: false,
    path: "",
  });
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({
    email: "",
    mailingList: false,
    pw1: "",
    pw2: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    pw1: "",
    pw2: "",
  });

  useEffect(() => {
    if (fireAuth.currentUser == null) {
      document.getElementById("email").focus();
    }
  }, []);

  useEffect(() => {
    if (code) {
      let ref = rtDatabase.ref("loginCodes/" + code);
      ref.on('value', snapshot => {
        if (!snapshot.exists()) {
          setRedirect({
            done: true,
            path: '/setup',
            state: {}
          })
        }
      })

      return () => ref.off();
    }
  }, [code])

  const validate = (prop, criteria, errorMessage) => {
    const message = criteria() ? "" : errorMessage;
    document.getElementById(prop).setCustomValidity(message);
    setErrors({ ...errors, [prop]: message });
    return criteria();
  };

  const multiValidate = (params) => {
    if (!Array.isArray(params)) {
      console.error(
        "multiValidate() Must be passsed an array of parameter objects"
      );
    }
    let newErrors = {};
    params.forEach((param) => {
      const message = param.criteria() ? "" : param.errorMessage;
      document.getElementById(param.prop).setCustomValidity(message);
      newErrors[param.prop] = message;
    });
    setErrors({ ...errors, ...newErrors });
  };

  const validateFuncs = {
    email: (val) =>
      validate(
        "email",
        () => /.+@.+\..+/.test(val),
        "Please enter a valid email"
      ),
    pw1: (val) =>
      multiValidate([
        {
          prop: "pw1",
          criteria: () => 6 <= val.length && val.length <= 15,
          errorMessage: "Please enter a valid password.",
        },
        {
          prop: "pw2",
          criteria: () => val === state.pw2,
          errorMessage: "Passwords do not match.",
        },
      ]),
    pw2: (val) =>
      validate("pw2", () => val === state.pw1, "Passwords do not match"),
  };

  const handleTextChange = (prop) => {
    return (event) => {
      let val = event.target.value;
      validateFuncs[prop](val);
      setState({ ...state, [prop]: val });
    };
  };

  const handleCheckChange = (prop) => {
    setState({ ...state, [prop]: !state[prop] });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var form = event.currentTarget;
    const valid = form.checkValidity();
    console.log("valid", valid);
    if (!valid) {
      multiValidate([
        {
          prop: "email",
          criteria: () => /.+@.+\..+/.test(state.email),
          errorMessage: "Please enter a valid email",
        },
        {
          prop: "pw1",
          criteria: () => 6 <= state.pw1.length && state.pw1.length <= 15,
          errorMessage: "Please enter a valid password.",
        },
        {
          prop: "pw2",
          criteria: () => state.pw1 === state.pw2,
          errorMessage: "Passwords do not match.",
        },
        {
          prop: "pw2",
          criteria: () => state.pw2 === state.pw1,
          errorMessage: "Passwords do not match",
        },
      ]);
      event.stopPropagation();
    } else {
      // triggers spinner in done button
      setLoading(true);
      console.log("what the frick");
      // Create account with firebase
      fireAuth
        .createUserWithEmailAndPassword(state.email, state.pw1)
        .then(() => {
          // auth account is created
          fireAuth.currentUser
            .updateProfile({ displayName: state.email })
            .then(() => {
              // auth account has display name
              var currDate = new Date();
              var updates = {};
              updates["users/" + fireAuth.currentUser.uid] = {
                email: state.email,
                uid: fireAuth.currentUser.uid,
                dateAdded: currDate.getTime(),
                mailingList: state.mailingList,
              };
              updates['userData/' + fireAuth.currentUser.uid] = {
                announcements: '',
                eaten: ['none'],
                signups: ['none']
              }
              updates["mailingList/" + fireAuth.currentUser.uid] = state.email;
              rtDatabase.ref().update(updates).then(() => {
                // user has been created in database
                sleep(500).then(() => {
                  setRedirect({
                    done: true,
                    path: '/',
                  });
                });
              })
              .catch((error) => {
                // delete auth user if db user fails
                console.error(error);
                fireAuth.currentUser.delete().then(() => {
                  // auth user is deleted
                });
              });

              // save credentials to code location
              if (code !== undefined) {
                let updates = {};
                updates["loginCodes/" + code] = {
                  username: state.email,
                  password: state.pw1
                }
                rtDatabase.ref().update(updates);
              }
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => {
          // Email is already in use
          if (error.code === "auth/email-already-in-use") {
            validate("email", () => false, error.message);
            setLoading(false);
          }
          console.error(error);
        });
    }
    setValidated(true);
  };

  const { currentUser } = useContext(AuthContext);
  if (currentUser) {
    return <Redirect to='/' />;
  }

  if (redirect.done) {
    return <Redirect to={redirect.path} />;
  }

  return (
    <div className="auth-page">
      <div className="auth-card">
        <h2 className="auth-header">Create Account</h2>
        {code !== undefined && 
          <h3 className="auth-subtitle">Create an account to authenticate your TV.</h3>
        }
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label className="auth-form-label">Email</Form.Label>
            <Form.Control
              className="auth-input"
              id="email"
              required
              type="email"
              placeholder="Email"
              value={state.email}
              onChange={handleTextChange("email")}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
            <Form.Text className="auth-form-text">
              We will not share your email with anyone.
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              checked={state.mailingList}
              onChange={() => handleCheckChange("mailingList")}
              className="auth-form-label"
              label="Add me to your mailing list"
            />
            <Form.Text className="auth-form-text">
              We'll give you a heads up when new updates and features are
              available. <br />
              We won't spam you.
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="auth-form-label">Password</Form.Label>
            <Form.Control
              className="auth-input"
              id="pw1"
              required
              type="password"
              placeholder="Enter password"
              value={state.pw1}
              onChange={handleTextChange("pw1")}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {errors.pw1}
            </Form.Control.Feedback>
            <Form.Text className="auth-form-text">
              Password must be 6-15 characters in length.
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3">
            {/* <Form.Label>Confirm Password</Form.Label> */}
            <Form.Control
              className="auth-input"
              id="pw2"
              required
              type="password"
              placeholder="Re-enter password"
              value={state.pw2}
              onChange={handleTextChange("pw2")}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              {errors.pw2}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="auth-links">
            <div className="auth-form-label">
              Already have an account? <Link to={{
                pathname: "/log-in",
                state: {code: code}
              }}>Log In</Link>
            </div>
          </div>
          <Button 
            className="auth-submit-btn"
            variant="primary" 
            type="submit" 
            disabled={loading}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <>Submit</>
            )}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default CreateAccount;
