import firebase from 'firebase';

// will use firebase emulator if set to true
var useEmulator = true;

// Set the configuration for your app
// TODO: Replace with your project's config object
const firebaseConfig = {
    apiKey: "AIzaSyCCFuOfq1Cu5ynO5G7YHN89TeitWBWX4_U",
    authDomain: "meal-dashboard.firebaseapp.com",
    projectId: "meal-dashboard",
    storageBucket: "meal-dashboard.appspot.com",
    messagingSenderId: "1086780304825",
    appId: "1:1086780304825:web:03eb7077ab7e1545db9a68",
    measurementId: "G-HWXH4673NH"
};
firebase.initializeApp(firebaseConfig);

export var rtDatabase = firebase.database();
export var fireAuth = firebase.auth();
export var fireStorage = firebase.storage();
export var fireFuncs = firebase.functions();
if (window.location.hostname === "localhost" && useEmulator) {
    rtDatabase.useEmulator("localhost", 8001);
    fireAuth.useEmulator("http://localhost:9099");
    fireStorage.useEmulator("localhost", 9199);
    fireFuncs.useEmulator("localhost", 5001);
} else {
    console.log("not using emulator.");
}

// Get a reference to the database service
// export var rtDatabase = firebase.database();
// export var fireAuth = firebase.auth();
