import React, { useEffect, useState } from "react";
import { rtDatabase, fireStorage } from "./../../Fire";
import firebase from "firebase";
import { Button, Modal, Form, Spinner, ProgressBar } from "react-bootstrap";
import "./PicUploadModal.css";
import { format } from "libphonenumber-js";

const PicUploadModal = (props) => {
  const { 
    onHide, 
    folder, 
    linkSaveLocation, 
    linkReturn, 
    empty, 
    show, 
    title,
    dims,               // obj: height (int), width (int)
    imgProps,           // obj: any other data to add to db entry for image
  } = props;
  const [localShow, setLocalShow] = useState(show);
  const [file, setFile] = useState(undefined);
  const [errors, setErrors] = useState({
    file: "Please select a file"
  })
  const [validated, setValidated] = useState(false);
  const [tempURL, setTempURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileDims, setFileDims] = useState(null);

  // checks
  console.log("link save location", linkSaveLocation);

  const handleHide = () => {
    setTempURL(null);
    setFile(null);
    setLocalShow(false);
    onHide();
  };

  const getImgDims = file => {
    try {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        let img = new Image();
        img.src = e.target.result;
        img.onload = function() {
          setFileDims({
            height: this.height,
            width: this.width
          })
        }
      }
    } catch (error) {
      // ignore this error, usually caused by pressing cancel in the file chooser
    }
  }

  const handleUploadChange = (e) => {
    let currFile = e.target.files[0];
    console.log("curr file", currFile);
    getImgDims(currFile);
    setFile(currFile);
    try {
      setTempURL(URL.createObjectURL(currFile));
    } catch (error) {}
  };

  useEffect(() => {
    if (fileDims) {
      console.log("File dims changed")
      validate(
        'file',
        () => fileDims.height === dims.height && fileDims.width === dims.width,
        `Image is not ${dims.width}x${dims.height}px.`
      )
    }
  }, [fileDims])

  const handleConfirmUpload = (event) => {
    // start loading spinner
    setLoading(true);

    let id = Date.now();
    var uploadTask = fireStorage
      .ref()
      .child("images/" + folder + "/" + id)
      .put(file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            console.log("user doesn't have permission");
            break;
          case "storage/canceled":
            // User canceled the upload
            console.log("user cancelled upload");
            break;
          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            console.log("unknown error");
            break;
          default:
            break;
        }
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          if (linkSaveLocation !== undefined) {
            let updates = {};
            updates[linkSaveLocation + "/" + id] = {
              link: downloadURL,
              show: true,
              id: id,
              ...imgProps
            };

            rtDatabase
              .ref()
              .update(updates)
              .then(() => {
                // window.location.reload();
                if (linkReturn !== undefined) {
                  linkReturn(downloadURL);
                }
                handleHide();
              });
          } else {
            console.error(
              "ERROR!! You did not pass a LinkSaveLocation prop to this component!!"
            );
          }
        });
      }
    );
  };

  /* 
    used to validate all form fields and handle error messaging
    prop: str - state/field prop to validate
    criteria: func - called to check prop
    errorMessage: str - error to be shown if !criteria()
  */
  const validate = (prop, criteria, errorMessage) => {
    const message = criteria() ? '' : errorMessage;
    document.getElementById(prop).setCustomValidity(message);
    setErrors({...errors, [prop]: message});
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    } else {
      handleConfirmUpload();
    }
    setValidated(true);
  };

  return (
    <>
      <Modal centered show={localShow} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form 
            onSubmit={handleSubmit}
            validated={validated}
          >
            {file && (
              <div
                className={
                  tempURL === null ? "d-none" : "pic-upload-preview-container"
                }
              >
                <img
                  className="pic-upload-modal-profile-preview"
                  alt="preview"
                  src={tempURL}
                />
              </div>
            )}
            <Form.Group className="pm-file-group">
              <Form.Label>Upload .jpg or .png, {dims.width}x{dims.height}px</Form.Label>
              <Form.Control
                type="file"
                onChange={handleUploadChange}
                accept=".jpg, .png"
                label="Upload .jpg or .png"
                id="file"
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.file}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="upload-confirm-btn-wrapper">
              <Button
                className="pm-submit-btn"
                variant="primary"
                disabled={file === undefined || loading}
                type="submit"
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <>Submit</>
                )}
              </Button>
            </div>
            {uploadProgress > 0 && (
              <ProgressBar now={uploadProgress} style={{ marginTop: "10px" }} />
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PicUploadModal;
