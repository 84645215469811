import React, { useState, useEffect } from "react";
import { rtDatabase, fireAuth } from "./../../../Fire";
import { Redirect } from "react-router";
import Loading from "./../loading/Loading";
import Banner from "./components/banner/Banner";
import Announcements from "./components/announcements/Announcements";
import ColorGroups from "./components/color-groups/ColorGroups";
import Schedule from "./components/schedule/Schedule";
import { groupTypes, GroupData } from "../../../data/GroupData";
import { capitalize } from "./../../../resources/Functions";
import "./Dashboard.css";
import "./Ticker.css";

const Dashboard = () => {
  const [data, setData] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const uid = fireAuth.currentUser.uid;
    const ref = rtDatabase.ref("userData/" + uid);
    ref.on("value", (snapshot) => {
      setData(snapshot.val());
    });

    return () => {
      ref.off();
    }
  }, []);

  if (error !== "") {
    return (
      <Redirect
        to={{
          pathname: "/error",
          state: { code: error.code, message: error.message },
        }}
      />
    );
  }
  if (data === "") {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const showGroups = (data.settings || {showGroups: true}).showGroups;

  return (
    <>
      <div className="db-page">
        <div className={(showGroups ? "db-carousel" : "db-carousel-full")}>
          <Banner 
            settings={data.settings}
            className={showGroups ? "db-carousel" : "db-carousel-full"}
          />
        </div>
        {showGroups && 
          <div className="db-groups-grid">
            {groupTypes.map((type) => (
              <div className="db-groups-grid-item">
                <h2 className="db-groups-title">{type.title}</h2>
                <div className="db-color-grid">
                  {Object.values(data[type.dbName])
                    .filter((color) => {
                      if (Object.values(data[type.dbName]).includes('seconds')) {
                        return color === 'seconds';
                      } else {
                        return color !== "none"
                      }
                    })
                    .map((color) => (
                      <div className={
                        "db-color-grid-item " +
                        (color === "seconds" ? "db-grid-seconds" : "")
                      }>
                        <div
                          className={"db-color-card db-" + color + "-card"}
                          style={{
                            backgroundImage:
                              "linear-gradient(" +
                              GroupData[color].gradient +
                              ")",
                          }}
                        >
                          <h3 className="db-color-card-text">
                            {color === "seconds" ? "" + capitalize(color) + "" : capitalize(color)}
                          </h3>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        }
      </div>
    </>
  );
};

export default Dashboard;
