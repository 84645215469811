export const groupTypes = [
    {
        title: 'Food:',
        rmTitle: 'Groups through Food',
        dbName: 'eaten'
    },
    {
        title: 'Signups:',
        rmTitle: 'Groups through Signups',
        dbName: 'signups'
    }
]

export const GroupData = {
    none: {
        name: '',
        gradient: 'rgb(0, 0, 0), rgb(0, 0, 0)',
    },
    pink: {
        name: 'Pink', 
        gradient: 'rgb(242, 144, 255), rgb(255, 92, 233)',
        backgroundColor: '#ff8297',
        color: '#ff8297',
    },
    purple: {
        name: 'Purple',
        gradient: 'rgb(170, 113, 255), rgb(40, 0, 58)',
        backgroundColor: '#a742cf',
        color: '#d492ee',
    },
    red: {
        name: 'Red',
        gradient: 'rgb(255, 95, 95), rgb(73, 0, 0)',
        backgroundColor: '#dc3545',
        color: '#dc3545',
    },
    yellow: {
        name: 'Yellow',
        gradient: 'rgb(255, 208, 0), rgb(170, 145, 0)',
        backgroundColor: '#ffc107',
        color: '#ffc107',
    },
    blue: {
        name: 'Blue',
        gradient: 'rgb(38, 114, 255), rgb(0, 0, 129)',
        backgroundColor: '#0d6efd',
        color: '#0d6efd',
    },
    green: {
        name: 'Green',
        gradient: 'rgb(8, 248, 0), rgb(0, 73, 16)',
        backgroundColor: '#198754',
        color: '#198754',
    },
    seconds: {
        name: 'Seconds',
        gradient: 'rgb(150, 227, 255), rgb(191, 0, 194)',
        backgroundColor: '#198187',
        color: '#198187',
    }
}

// values need to be in quotes
export const ActiveGroups = {
    red: "1",
    blue: "2",
    pink: "3",
    green: "4"
}