export const copyStringToClipboard = str => {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
}

/*
    function: toMili 
    ================================================
    str: should be time of format "hh:mm"
    return: int of the time converted to miliseconds
*/
export const toMili = str => {
    var hours = parseInt(str.replace(/(\d+):(\d+)/g, '$1'));
    var minutes = parseInt(str.replace(/(\d+):(\d+)/g, '$2'));
    return (hours * 60 + minutes) * 60 * 1000;
}

export const miliToDays = ms => {
    return Math.floor(ms / 1000 / 60 / 60 / 24);
}

export const dayInMili = 24 * 60 * 60 * 1000;
export const weekInMili = 7 * dayInMili;
export const getBlankGoal = () => {
    const currDate = new Date();
    const defaultDate = new Date(currDate.getTime() + weekInMili);
    const dateProp = new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate(), 0, 0, 0).getTime() + weekInMili;
    let hours = defaultDate.getHours().toString();
    hours = hours.length === 1 ? '0' + hours : hours;
    let minutes = defaultDate.getMinutes().toString();
    minutes = minutes.length === 1 ? '0' + minutes : minutes;
    const timeProp = hours + ":" + minutes;
    return {
        title: '',
        description: '',
        // round to day time is in
        date: dateProp,
        time: timeProp,
        dateTime: dateProp + toMili(timeProp)
    }
}

export const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export let addOrderSuffix = int => {
    let str = int.toString();
    if (int === 1 || (int % 10 === 1 && int % 100 !== 11)) {
        str += 'st';
    } else if (int === 2 || (int % 10 === 2 && int % 100 !== 12)) {
        str += 'nd';
    } else if (int === 3 || (int % 10 === 3 && int % 100 !== 13)) {
        str += 'rd';
    } else {
        str += 'th';
    }
    return str;
}