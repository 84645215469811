import React, { useContext, useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import ForgotPassword from "./forgot-password/ForgotPassword";
import Alert, { emptyAlert } from "./../../alert/Alert";
import { sleep } from "../../../resources/Functions";
import { Form, Button, Spinner } from "react-bootstrap";
import "./LogIn.css";
import { fireAuth, rtDatabase } from "../../../Fire";
import { AuthContext } from "./../../../Auth";
import MobileLogin from "./mobile-login/MobileLogin";

const LogIn = (props) => {
  const {
    fromPath,
    code
  } = props.location.state || {}
  console.log("fromPath", fromPath)
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({
    email: "",
    pw: "",
  });
  const [errors, setErrors] = useState({
    email: "Please enter a valid email.",
    pw: "Please enter your password.",
  });
  const [showPwResetModal, setShowPwResetModal] = useState(false);
  const [alert, setAlert] = useState({ emptyAlert });
  const [redirect, setRedirect] = useState({
    done: false,
    path: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fireAuth.currentUser == null && fromPath !== "/tv") {
      document.getElementById("email").focus();
    }
  }, []);

  useEffect(() => {
    if (code) {
      let ref = rtDatabase.ref("loginCodes/" + code);
      ref.on('value', snapshot => {
        if (!snapshot.exists()) {
          setRedirect({
            done: true,
            path: '/setup',
            state: {}
          })
        }
      })

      return () => ref.off();
    }
  }, [code])

  const validate = (prop, criteria, errorMessage) => {
    const message = criteria() ? "" : errorMessage;
    document.getElementById(prop).setCustomValidity(message);
    setErrors({ ...errors, [prop]: message });
  };

  const handleChange = (prop) => {
    return (event) => {
      if (prop === "email") {
        validate(
          "email",
          () => /.+@.+\..+/.test(event.target.value),
          "Please enter a valid email."
        );
      } else if (prop === "pw") {
        validate("pw", () => true, "");
      }
      setState({ ...state, [prop]: event.target.value });
    };
  };

  const handleLogin = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setLoading(true);
      fireAuth
        .signInWithEmailAndPassword(state.email, state.pw)
        .then((user) => {
          // user is signed in
          
          // save credentials to code location
          console.log("LOGIN CODE", code);
          if (code !== undefined) {
            let updates = {};
            updates["loginCodes/" + code] = {
              username: state.email,
              password: state.pw
            }
            rtDatabase.ref().update(updates);
          }

          sleep(500).then(() => {
            setRedirect({
              done: true,
              path: "/",
            });
          });
        })
        .catch((error) => {
          setLoading(false);
          if (error.code === "auth/user-not-found") {
            validate(
              "email",
              () => false,
              "A user with this email does not exist."
            );
          } else if (error.code === "auth/wrong-password") {
            validate("pw", () => false, "This password is incorrect.");
          }
        });
    }
    setValidated(true);
  };

  const { currentUser } = useContext(AuthContext);
  if (currentUser && fromPath === "/tv") {
    return <Redirect to="/tv" />;
  } else if (currentUser) {
    return <Redirect to="/" />;
  }

  if (redirect.done) {
    return <Redirect to={{
      pathname: redirect.path,
      state: redirect.state
    }} />;
  }

  if (fromPath === "/tv") {
    return (
      <>
        <div className="auth-page">
          <MobileLogin />
        </div>
      </>
    )
  }

  return (
    <>
      <div className="auth-page">
        <div data-testid="log-in" className="auth-card">
          <h2 className="auth-header">TV Display Log-In</h2>
          {code !== undefined && 
            <h3 className="auth-subtitle">Log in to authenticate your TV.</h3>
          }
          <Form noValidate validated={validated} onSubmit={handleLogin}>
            <Form.Group className="mb-3">
              <Form.Label className="auth-form-label">Email</Form.Label>
              <Form.Control
                className="auth-input"
                id="email"
                required
                type="email"
                placeholder="Email"
                value={state.email}
                onChange={handleChange("email")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="auth-form-label">Password</Form.Label>
              <Form.Control
                className="auth-input"
                id="pw"
                required
                type="password"
                placeholder="Password"
                value={state.pw1}
                onChange={handleChange("pw")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.pw}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="auth-links">
              <div>
                <a
                  className="log-in-create-account-link"
                  onClick={() => {
                    setShowPwResetModal(true);
                  }}
                >
                  Forgot Password?
                </a>
              </div>
              <div>
                <Link to={{
                  pathname: "/create-account",
                  state: {code: code}
                }}>Create Account</Link>
              </div>
            </div>
            <Button
              className="auth-submit-btn"
              variant="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>Submit</>
              )}
            </Button>
          </Form>
        </div>
      </div>
      <ForgotPassword
        show={showPwResetModal}
        handleClose={() => setShowPwResetModal(false)}
        handleDone={() => {
          setShowPwResetModal(false);
          setAlert({
            show: true,
            title: "Email sent",
            message: "A password reset email has been sent",
            confirmCallback: () => {
              window.location.reload();
            },
          });
        }}
      />
      {alert.show && (
        <Alert
          show={alert.show}
          title={alert.title}
          message={alert.message}
          confirmCallback={alert.confirmCallback}
        />
      )}
    </>
  );
};

export default LogIn;
