import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { fireAuth } from '../../Fire';
import './NavBar.css';


const NavBar = props => {
    const [userName, setUserName] = useState('')
    const [userLoggedIn, setUserLoggedIn] = useState(false)

    /*
        Okay it seems like this isn't necessary. Like it seems like
        you could just hardcode these values into the states themselves,
        but for some reason when you do that, the render enters a loop
        and never loads. It makes no sense, but for some reason this
        useEffect is necessary.
    */
    useEffect(() => {
        if (fireAuth.currentUser != null) {
            var uName = fireAuth.currentUser.email;
            setUserName(uName)
            setUserLoggedIn(true)
        }
    }, [])

    const handleSignOut = event => {
        fireAuth.signOut().then(() => {
            setUserLoggedIn(false)
            setUserName('')
            window.location.reload();
        }).catch(error => {
            console.log("Signout failed");
            console.log("error code: " + error.code);
            console.log("error message: " + error.message);
        })
    }

    const navLinks = userLoggedIn ? (
        <>
            <Nav className="mr-auto">
                <Link className="nav-link app-desktop-only" to="/tv">TV View</Link>
            </Nav>
            <Nav>
                <NavDropdown menualign="right" title="Profile" id="basic-nav-dropdown">
                    {/* <Link href="/profile">View Profile(!!)</Link> */}
                    <NavDropdown.Item className="nav-dropdown-item" onClick={handleSignOut}>Sign out</NavDropdown.Item>
                </NavDropdown>
                <Navbar.Text className="navbar-profile-name">{userName}</Navbar.Text>
            </Nav>
        </>
    ) : (
        <>
            <Nav className="mr-auto">
                {/* <Link className="nav-link" to="/help">Setup</Link> */}
            </Nav>
            <Nav>
                <Button 
                    className="nav-link" 
                    variant="link" 
                    href="/log-in"
                >Sign in</Button>
            </Nav>
        </>
    )
    
    return (
        <div>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Link className="navbar-brand" to="/">
                    MivodenTV
                </Link>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    {navLinks}
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default NavBar
