import React, { useState } from 'react';
import { fireAuth } from './../../../../Fire';
import { Modal, Button, Form } from 'react-bootstrap';
import './ForgotPassword.css';

// props
// show: true or false
// handleClose: callback to handle cancel
// handleDone: callback to handle email sent
const ForgotPassword = props => {
    const [validated, setValidated] = useState(false);
    const [state, setState] = useState({
        forgotPwEmail: ''
    });
    const [errors, setErrors] = useState({
        forgotPwEmail: "Please enter a valid email."
    })

    const validate = (prop, criteria, errorMessage) => {
        const message = criteria() ? '' : errorMessage;
        document.getElementById(prop).setCustomValidity(message);
        setErrors({...errors, [prop]: message});
    }

    const handleSubmit = event => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
        } else {
            fireAuth.sendPasswordResetEmail(state.forgotPwEmail).then(() => {
                props.handleDone();
            }).catch(error => {
                if (error.code === "auth/user-not-found") {
                    validate(
                        'forgotPwEmail', 
                        () => false, 
                        'A user with this email does not exist.'
                    );
                }
            })
        }
        setValidated(true);
    }

    const handleChange = prop => {
        return event => {
            if (prop === 'forgotPwEmail') {
                validate(
                    prop,
                    () => /.+@.+\..+/gm.test(event.target.value),
                    'Please enter a valid email'
                )
            }
            setState({...state, [prop]: event.target.value});
        }
    }

    // should have something to handle what happens if all the emails aren't loaded yet.

    return (
        <>
            <Modal 
                show={props.show} 
                onHide={props.handleClose}
                centered
                className="app-modal forgot-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <Form.Group className="mb-3">
                            <Form.Label>Enter the email associated with your account.</Form.Label>
                            <Form.Control
                                id="forgotPwEmail"
                                required
                                type="email"
                                placeholder="Email"
                                value={state.forgotPwEmail}
                                onChange={handleChange("forgotPwEmail")}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type='invalid'>{errors.forgotPwEmail}</Form.Control.Feedback>
                        </Form.Group>
                        <Button type="submit">Send Password Reset Email</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ForgotPassword;