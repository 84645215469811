import React, { useState, useEffect, useContext } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { GroupData } from '../../../../../data/GroupData';
import { fireAuth, rtDatabase } from '../../../../../Fire';
import { capitalize } from '../../../../../resources/Functions';

export default function ActiveGroups(props) {
  const {
    activeGroups
  } = props;

  console.log("ACTIVE GROUPS", activeGroups);
  console.log("UID", fireAuth.currentUser.uid);

  const [colors, setColors] = useState(Object.keys(activeGroups || {}) || []);
  const [submitting, setSubmitting] = useState(false);

  const handleCheck = key => {
    if (colors.includes(key)) {
      // set to false
      setColors(prev => prev.filter(color => color !== key));
    } else {
      // set to true
      setColors(prev => ([...prev, key]))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const obj = {};
    colors.forEach(color => obj[color] = capitalize(color));
    let updates = {};
    updates[`userData/${fireAuth.currentUser.uid}/settings/activeGroups`] = obj;
    updates[`userData/${fireAuth.currentUser.uid}/eaten`] = {0: 'none'};
    updates[`userData/${fireAuth.currentUser.uid}/signups`] = {0: 'none'};
    rtDatabase.ref().update(updates).then(() => {
      setSubmitting(false);
    }).catch(error => {
      console.error(error);
      setSubmitting(false);
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      {colors && <Form.Group className="mb-3">
        <div>
          <Form.Label className="hgs-color-checks">Active Color Groups</Form.Label>
        </div>
        {Object.keys(GroupData).filter(key => key !== 'none' && key !== 'seconds').map(key => (
          <Form.Check
            className="hgs-color-checks"
            key={key}
            inline
            label={GroupData[key].name}
            type="checkbox"
            checked={colors.includes(key)}
            onChange={() => handleCheck(key)}
          />
        ))}
      </Form.Group>}
      <Button
        variant="primary"
        type="submit"
        disabled={submitting}
      >
        {submitting ? <Spinner size="sm" animation="border" /> : "Save Colors"}
      </Button>
    </Form>
  )
}