import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { rtDatabase, fireAuth } from '../../../Fire';
import { Redirect } from 'react-router-dom';
import "./Setup.css";

const Setup = () => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    code: "",
  })
  const [errors, setErrors] = useState({
    code: "Please enter a valid code from your tv.",
  })
  const [redirect, setRedirect] = useState({
    go: false,
    path: ""
  })

  useEffect(() => {
    fireAuth.signOut();
  }, [])

  const validate = (prop, criteria, errorMessage) => {
    const message = criteria() ? "" : errorMessage;
    document.getElementById(prop).setCustomValidity(message);
    setErrors({ ...errors, [prop]: message });
  };

  const handleChange = (prop) => {
    return (e) => {
      if (prop === "code") {
        if (e.target.value.length <= 6) {
          validate(
            "code",
            () => /^\d{6}$/.test(e.target.value),
            "Please enter a valid code."
          );
          setState({
            ...state,
            [prop]: e.target.value
          })
        }
      } else if (prop === "pw") {
        validate("pw", () => true, "");
      }
      // setState({ ...state, [prop]: e.target.value });
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
    } else {
      setLoading(true);
      rtDatabase.ref("loginCodes/" + state.code).once("value").then(snapshot => {
        if (!snapshot.exists()) {
          setLoading(false);
          validate("code", () => false, "Code is not in use.")
        } else {
          setRedirect({
            go: true,
            path: "/log-in"
          })
        }
      })
    }
    setValidated(true);
  }

  if (redirect.go) {
    return <Redirect to={{
      pathname: redirect.path,
      state: {
        code: state.code
      }
    }} />
  }

  return <>
    <div className="auth-page">
      <div className="auth-card">
        <h2 className="auth-header">TV Display Setup</h2>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label
              className="su-input-label"
            >Enter the code displayed on your TV</Form.Label>
            <Form.Control
              id="code"
              className="su-code-input"
              required
              type="number"
              placeholder="000000"
              value={state.code}
              onChange={handleChange("code")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.code}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="su-btn-wrapper">
            <Button
              className="auth-submit-btn"
              variant="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>Submit</>
              )}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  </>
}

export default Setup;