import React, { useState, useEffect } from "react";
import NavBar from "./../../nav-bar/NavBar";
import { rtDatabase, fireAuth, fireStorage } from "./../../../Fire";
import { Redirect } from "react-router";
import Loading from "./../loading/Loading";
import PicUploadModal from "./../../pic-upload-modal/PicUploadModal";
import { Button } from "react-bootstrap";
import { ActiveGroups, groupTypes } from "../../../data/GroupData";
import {
  sleep,
  capitalize,
  addOrderSuffix,
} from "../../../resources/Functions";
import Alert, { emptyAlert } from "../../alert/Alert";
import EditPhotoModal from "./components/edit-photo-modal/EditPhotoModal";
import Settings from './components/settings/Settings';
import "./Homepage.css";

const Homepage = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState("");
  const [redirect, setRedirect] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState({
    show: false,
    pic: {}
  });
  const [alert, setAlert] = useState(emptyAlert);

  useEffect(() => {
    console.log("Component did mount");
    if (fireAuth.currentUser !== null) {
      const ref = rtDatabase.ref("userData/" + fireAuth.currentUser.uid)
      ref.on("value", (snapshot) => {
        setUserData(snapshot.val());
      });

      return () => ref.off();
    }
  }, []);

  const handleGroupChange = (type, color) => {
    return () => {
      // validate type param
      if (type !== "eaten" && type !== "signups") {
        throw new Error("type is not eaten or signups");
      }

      // get array from database
      let arr = [];
      try {
        arr = Object.values(userData[type]);
      } catch {
        arr = [];
      }

      // take out 'none'
      if (arr.includes("none")) {
        arr.splice(arr.indexOf("none"), 1);
      }

      // add or remove color
      if (arr.includes(color)) {
        // take out of array
        arr.splice(arr.indexOf(color), 1);
        console.log("arr after splice", arr);
      } else {
        // push new color to front of array
        arr = [color].concat(arr);
      }

      // add 'none' if the array is empty so the prop will still be in the database
      if (arr.length === 0) {
        arr = ["none"];
      }
      rtDatabase
        .ref("userData/" + fireAuth.currentUser.uid + "/" + type)
        .set(arr);
    };
  };

  const deleteImg = (id) => {
    setEditModal({
      show: false,
      pic: {}
    })
    let updates = {};
    updates["userData/" + fireAuth.currentUser.uid + "/carouselPics/" + id] =
      null;
    rtDatabase.ref().update(updates);
    fireStorage
      .ref()
      .child("images/" + fireAuth.currentUser.uid + "/carouselPics/" + id)
      .delete();
  };

  if (error !== "") {
    return (
      <Redirect
        to={{
          pathname: "/error",
          state: { code: error.code, message: error.message },
        }}
      />
    );
  }
  if (userData === null) {
    return <Loading />;
  }

  const showGroups = (userData.settings || {showGroups: true}).showGroups;
  return (
    <>
      <NavBar />
      <div className="hp-page">
        <div className="hp-content">
          <div className="hp-page-section">
            <div className="hp-switch-section">
              {userData && <Settings
                showGroups={userData.settings?.showGroups}
                activeGroups={userData.settings?.activeGroups}
              />}
            </div>
            <h2 className="hp-title">Carousel Images</h2>
            <h3 className="hp-title-3">{showGroups ? "(narrow)" : "(16:9)"}</h3>
            <div className="hp-carousel-imgs">
              {Object.keys(userData.carouselPics || {})
                .filter(id => {
                  const criteria = userData.carouselPics[id].showGroups;
                  const show = (userData.settings || {}).showGroups === undefined ? true : userData.settings.showGroups;
                  if (show) {
                    return (criteria === undefined ? true : criteria);
                  } else {
                    return !(criteria === undefined ? true : criteria);
                  }
                })
                .map((id) => (
                  <div className="hp-carousel-item" key={id}>
                    <img
                      className="hp-carousel-img"
                      src={userData.carouselPics[id].link}
                      alt="carousel image"
                    />
                    <div 
                      className={"hp-img-x-anchor " + (userData.carouselPics[id].show ? "d-none" : "")}
                    >
                      <div className="hp-img-hidden">
                        <i className="bi-eye-slash hp-img-hidden-icon" />
                      </div>
                    </div>
                    <div className="hp-img-x-anchor">
                      <button
                        className="hp-img-wrapper"
                        onClick={() => setEditModal({
                          show: true,
                          pic: userData.carouselPics[id]
                        })}
                      >
                        <i className="bi-three-dots hp-img-three-dots" />
                      </button>
                    </div>
                  </div>
                ))}
            </div>
            <div className="hp-upload-btn-container">
              <div className="hp-upload-btn">
                <Button variant="info" onClick={() => setShowModal(true)}>
                  Upload Carousel Image
                </Button>
              </div>
            </div>
          </div>

          {(showGroups && userData) && 
            <div className="hp-groups-section">
              <h2 className="hp-title">Color Group Controls</h2>
              <div className="hp-groups-container">
                {groupTypes.map((type) => {
                  let arr = Object.values(userData[type.dbName]);
                  return (
                    <div key={type} className="hp-page-section hp-group-section">
                      <h3 className="hp-title-3">{type.rmTitle}</h3>
                      <div className="hp-group-btns-grid">
                        {Object.keys(userData?.settings?.activeGroups || {}).map((color) => {
                          let index = arr.indexOf(color);
                          return (
                            <Button
                              key={color}
                              className={
                                "hp-btn hp-" +
                                color +
                                "-btn " +
                                (arr.includes(color)
                                  ? "hp-" + color + "-btn-checked"
                                  : "")
                              }
                              onClick={handleGroupChange(type.dbName, color)}
                            >
                              {capitalize(color)}
                              {index !== -1
                                ? ": " + addOrderSuffix(arr.length - index)
                                : ""}
                            </Button>
                          );
                        })}
                        {type.dbName === 'eaten' && <Button
                          className={
                            "hp-btn hp-" + "seconds" + "-btn " +
                            (arr.includes("seconds")
                              ? "hp-" + "seconds" + "-btn-checked"
                              : "")
                          }
                          onClick={handleGroupChange(type.dbName, "seconds")}
                        >
                          {capitalize("seconds")}
                        </Button>}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          }
        </div>
      </div>
      {showModal && (
        <PicUploadModal
          title="Upload Carousel Photo"
          onHide={() => sleep(500).then(() => setShowModal(false))}
          show={showModal}
          folder={fireAuth.currentUser.uid + "/carouselPics"}
          linkSaveLocation={
            "userData/" + fireAuth.currentUser.uid + "/carouselPics"
          }
          dims={
            (userData.settings || {}).showGroups ? 
              {width: 2880, height: 1180} :
              {width: 2880, height: 1620}
          }
          imgProps={{
            showGroups: (userData.settings || {}).showGroups
          }}
        />
      )}
      {editModal.show && (
        <EditPhotoModal
          show={editModal.show}
          onHide={() => {
            setEditModal({
              show: false,
              pic: {}
            });
          }}
          pic={editModal.pic}
          deleteImg={deleteImg}
        />
      )}
      {alert.show && (
        <Alert
          show={alert.show}
          title={alert.title}
          message={alert.message}
          actionNeeded={alert.actionNeeded}
          confirmText={alert.confirmText}
          variant={alert.variant}
          confirmCallback={alert.confirmCallback}
          cancelCallback={alert.cancelCallback}
        />
      )}
    </>
  );
};

export default Homepage;
